.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;  
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;

    padding-left: 0;
    padding-right: 0;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: #04AA6D;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
  }

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 95vw;
}

.slideshowSlider {
  white-space: nowrap;

  transition: ease 1000ms;
}

.slide {
  display: inline-block !important;
  width: 100%;
}