/* Central Wobbly Circle */

.wobbleContainer {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px; 
	/* transform: translate3d(-50%, 0, 0); */
}

.wobbleImage {
	grid-area: 1 / 1 / 2 / 2;
	margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

.wobbleContainer .wobble {
	grid-area: 1 / 1 / 2 / 2;
	margin-left: auto;
    margin-right: auto;
	width: 400px;
	height: 400px;
	background: #FB98FB;
	border-radius: 50%;
	transition: all 2s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	animation: rotation 15s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* Animated Teavana Title */

.teavanaTitle {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
	/* transform: translate3d(-25%, -50%, 0); */
}

/* Google chrome */
@-webkit-keyframes svg-text-anim {
40% {
	stroke-dashoffset: 0;
	fill: transparent;
	}
	60% {
	stroke-dashoffset: 0;
	fill: #fb98fb;
	}
	100% {
	stroke-dashoffset: 0;
	fill: #fb98fb;
	}
	
}
/* Most browsers */
@keyframes svg-text-anim {
40% {
	stroke-dashoffset: 0;
	fill: transparent;
	}
	60% {
	stroke-dashoffset: 0;
	fill: #fb98fb;
	}
	100% {
	stroke-dashoffset: 0;
	fill: #fb98fb;
	}
	
}